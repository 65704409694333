import AuthereumAccount from './AuthereumAccount.json'
import BalancerVault from './BalancerVault.json'
import EnsEthRegistrarController from './EnsEthRegistrarController.json'
import EnsPublicResolver from './EnsPublicResolver.json'
import EnsToken from './EnsToken.json'
import ERC1271 from './ERC1271.json'
import ERC20 from './ERC20.json'
import ERC721 from './ERC721.json'
import SaddleSwap from './SaddleSwap.json'
import UniswapV2Factory from './UniswapV2Factory.json'
import UniswapV2Pair from './UniswapV2Pair.json'
import UniswapV2Router02 from './UniswapV2Router02.json'
import UniswapV3Factory from './UniswapV3Factory.json'
import UniswapV3Pool from './UniswapV3Pool.json'
import UniswapV3SwapRouter from './UniswapV3SwapRouter.json'
import WETH from './WETH.json'
import yVault from './yVault.json'
import Engine from './Engine.json'
import AllocateHandler from './AllocateHandler.json'
import StateCounter from './StateCounter.json'
import Community from './Community.json'
import CommunityStake from './CommunityStake.json'
import BridgeService from './BridgeService.json'
import ERC20Aggregation from "./ERC20Aggregation.json"
import ERC721Aggregation from "./ERC721Aggregation.json"
import ERC1155Aggregation from "./ERC1155Aggregation.json"

const abis = {
  AuthereumAccount,
  BalancerVault,
  EnsEthRegistrarController,
  EnsPublicResolver,
  EnsToken,
  ERC1271,
  ERC20,
  ERC721,
  SaddleSwap,
  UniswapV2Factory,
  UniswapV2Pair,
  UniswapV2Router02,
  UniswapV3Factory,
  UniswapV3Pool,
  UniswapV3SwapRouter,
  WETH,
  yVault,
  Engine,
  AllocateHandler,
  StateCounter,
  Community,
  CommunityStake,
  BridgeService,
  ERC20Aggregation,
  ERC721Aggregation,
  ERC1155Aggregation
}

export default abis
